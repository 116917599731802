import { ContentDelivery, useContentDeliveryAPI, useEpiserver } from '@episerver/spa-core';
import { SubMenuDto } from '../../../../Models/Interfaces/ApiResponse/TransportBlockApiResponse';
import { getLocalizationText, General } from '../../../../utils/getLocalizationText';
import { OldDomainPageType } from '../../../../Models/Content/OldDomainPageData';
import { useEffect, useState } from 'react';
import IContent from '@episerver/spa-core/dist/Models/IContent';
import { GoToTopAnchor } from './GoToTopAnchor';
import { SkipToContent } from './SkipToContent';
import OldBreadcrumbs from './OldBreadcrumbs';
import { GoToTopLink } from './GoToTopLink';
import { getDescription } from '../../Utils';
import OldFeedback from './OldFeedback';
import { Helmet } from 'react-helmet-async';
import OldFooter from './OldFooter';
import MainMenu from './MainMenu';
import SiteTop from './SiteTop';
import axios from 'axios';
import { ArticlePageType } from 'Models/ArticlePageData';
import { SiteMapPageType } from 'Models/SiteMapPageData';
import { OldSearchPageType } from 'Models/OldSearchPageData';
import { DummyPageType } from 'Models/DummyPageData';
import { TransportPageType } from 'Models/Content/TransportPageData';
import { OldBerammingSokPageType } from 'Models/OldBerammingSokPageData';
import { TagPageType } from 'Models/TagPageData';
import { MoseyLayoutProps } from '../../../Layout/MoseyLayoutProps';

function ShouldHideDidYouFind(currentContent: CurrentContent): boolean {
    if ('hideDidYouFind' in currentContent) {
        return currentContent.hideDidYouFind.value;
    }
    return true;
}

// Every possible OldDomstol Page Types
export type CurrentContent =
    | OldDomainPageType
    | ArticlePageType
    | OldSearchPageType
    | SiteMapPageType
    | DummyPageType
    | TransportPageType
    | OldBerammingSokPageType
    | TagPageType;

const apiUrl = '/api/episerver/v3/mainmenu';

export const OldLayout = (
    props: MoseyLayoutProps & {
        currentContent: CurrentContent;
        layoutSettings?: ContentDelivery.StringProperty;
        language?: string;
        pathname?: string;
        children?: React.ReactNode;
    }
) => {
    const api = useContentDeliveryAPI();
    const [domainPage, setDomainPage] = useState<OldDomainPageType>();
    const [mainMenu, setMainMenu] = useState<SubMenuDto[]>();
    const context = useEpiserver();

    const language = props.currentContent?.language?.name ?? 'no';

    useEffect(() => {
        const updateMenu = (domainPage: OldDomainPageType) => {
            if (domainPage.cssBodyClass?.value) {
                document.body.classList.add(domainPage.cssBodyClass?.value);
            }

            const url = new URL(domainPage?.contentLink?.url);

            axios
                .get<SubMenuDto[]>(`${apiUrl}?url=${url.pathname}&language=${domainPage.language?.name}`)
                .then((childrenList) => {
                    setMainMenu(childrenList.data);
                });
        };

        const getDomainPageFromAncestors = (currentContent: IContent) => {
            api.Language = currentContent.language?.name ?? 'no';
            api.getAncestors(currentContent).then((ancestors) => {
                const currentDomainPage = ancestors.find((ancestor) => ancestor.contentType.includes('OldDomainPage'));

                if (currentDomainPage) {
                    setDomainPage(currentDomainPage as OldDomainPageType);
                    updateMenu(currentDomainPage as OldDomainPageType);
                }
            });
        };

        if (props.currentContent?.contentType.includes('OldDomainPage')) {
            setDomainPage(props.currentContent as OldDomainPageType);
            updateMenu(props.currentContent as OldDomainPageType);
        } else if (props.currentContent && (!domainPage || domainPage.language?.name !== language)) {
            getDomainPageFromAncestors(props.currentContent);
        }
    }, [api, domainPage, language, props.currentContent]);

    if (!domainPage || !mainMenu) {
        return null;
    }

    const hideDidYouFind = ShouldHideDidYouFind(props.currentContent);
    const norwegianCourtsLocaleText = getLocalizationText(language, [General, 'NorwegianCourts']);
    return (
        <>
            <SkipToContent language={props.language ?? 'no'} />
            <GoToTopAnchor />
            <div className="page-wrapper old" style={context.isServerSideRendering() ? { visibility: 'hidden' } : {}}>
                <Helmet htmlAttributes={{ lang: props.language }}>
                    <title>
                        {(props.currentContent?.heading?.value
                            ? props.currentContent?.heading?.value
                            : props.currentContent.name) + norwegianCourtsLocaleText
                            ? ' | ' + norwegianCourtsLocaleText
                            : ''}
                    </title>
                    <meta
                        property="og:title"
                        content={
                            props.currentContent?.heading?.value
                                ? props.currentContent?.heading?.value
                                : props.currentContent?.name.toString()
                        }
                    />
                    <meta property="og:type" content="website" />
                    <meta property="og:locale" content={props.language ?? 'no'} />
                    <meta property="og:description" content={getDescription(props.currentContent)} />
                    <meta property="description" name="description" content={getDescription(props.currentContent)} />
                    <link rel="shortcut icon" href="/spaview/app.html.spa/favicon.svg" type="image/svg+xml" />
                    <link rel="stylesheet" href="/StaticContent/oldDomstol/hoyesterett.min.css" />
                </Helmet>
                <header className="page-header">
                    <div className="container">
                        <SiteTop domainPage={domainPage} currentPage={props.currentContent} />
                        <MainMenu domainPage={domainPage} currentPage={props.currentContent} menu={mainMenu} />
                    </div>
                    <OldBreadcrumbs currentPage={props.currentContent} />
                </header>

                <main className="container" id="main-content" tabIndex={-1}>
                    {props.children}
                </main>

                {!hideDidYouFind && (
                    <div className="layout-row">
                        <OldFeedback currentPage={props.currentContent} layoutSettings={props.layoutSettings} />
                    </div>
                )}

                {domainPage && <OldFooter domainPage={domainPage} />}
            </div>
            <GoToTopLink />
        </>
    );
};

export default OldLayout;
